<template>
  <div>
    <Header></Header>
    <el-alert
      class="tips"
      title="仅限悦教大讲堂、外研拼读、悦测评、iWay用户注册"
      type="warning"
      center
      show-icon
    />
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>注册</span>
        </div>
      </template>
      <el-form
        ref="rFormRef"
        :model="rForm"
        :rules="rRules"
        label-width="100px"
      >
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="rForm.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item class="warnigBig" label="密 码" prop="password">
          <el-input
            v-model="rForm.password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="8-32位，含大小写字母、数字或特殊符号的其中两项"
          />
          <div class="eye-p">
            <img
              v-show="showPassword"
              src="@/assets/image/eye.png"
              @click="showPassword = false"
            />
            <img
              v-show="!showPassword"
              src="@/assets/image/closeEye.png"
              @click="showPassword = true"
            />
          </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            v-model="rForm.rePassword"
            :type="showPassword2 ? 'text' : 'password'"
            placeholder="需和上次输入一致"
          />
          <div class="eye-p">
            <img
              v-show="showPassword2"
              src="@/assets/image/eye.png"
              @click="showPassword2 = false"
            />
            <img
              v-show="!showPassword2"
              src="@/assets/image/closeEye.png"
              @click="showPassword2 = true"
            />
          </div>
        </el-form-item>
        <el-form-item label="验证码" prop="sms">
          <el-row :gutter="20">
            <el-col :span="18" :xs="14">
              <el-input
                v-model="rForm.sms"
                placeholder="请输入验证码"
                @blur="verifyCode(rForm.tel, rForm.sms, 'register')"
              />
            </el-col>
            <el-col v-show="sendBtnShow" :span="6" :xs="4">
              <el-button
                @click="
                  sendVerify(rForm.tel, 'register');
                  setTime();
                "
                >发送验证码</el-button
              >
            </el-col>
            <el-col v-show="!sendBtnShow" :span="6">
              <el-button>重新发送({{ counter }}s)</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="mobileAgreement">
          <el-checkbox
            v-model="customCheck"
            type="checkbox"
            class="custom-control-input"
            id="customCheck"
          />
          <label class="custom-control-label" for="customCheck"
            >我已阅读并同意<router-link target="_blank" to="/agreement"
              >《外研教学评用户协议》</router-link
            >
            <router-link target="_blank" to="/privacy"
              >《外研教学评隐私政策》</router-link
            ></label
          >
        </el-form-item>
        <el-form-item class="mobile-btn">
          <el-button class="largeBtn" type="primary" @click="register()"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
      <div class="clearFloat">
        <div class="floatR">
          <router-link to="/login">登录系统</router-link>
        </div>
      </div>
    </el-card>
    <el-dialog
      v-model="confirmAge"
      title="是否满14周岁"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="confirmTipText">
        您的年龄是否大于14周岁：
        <el-radio-group v-model="ageRadio" class="ml-4">
          <el-radio label="1" size="large" @click="confirmAge = false"
            >是</el-radio
          >
          <el-radio
            label="2"
            size="large"
            @click="
              ageTips = true;
              confirmAge = false;
            "
            >否</el-radio
          >
        </el-radio-group>
      </div>
    </el-dialog>
    <el-dialog
      v-model="ageTips"
      title="未满14周岁提醒"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="tipText">
        您好，本产品只限已满14周岁的人员注册，如果您是未满14周岁的儿童，请您邀请您的监护人作为本产品的注册用户，您在您的监护人的同意下使用本产品。
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="ageTips = false"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { verifyCode } from "@/utils/utils.js";
import { toRefs, reactive, getCurrentInstance } from "vue";
import { toSendVerify, toRegister } from "@/api/api.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {
  validatePassword,
  validateTel,
  validateNull,
} from "@/utils/validate.js";
export default {
  components: { Header },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      rForm: {
        tel: "",
        password: "",
        rePassword: "",
        sms: "",
      },
      rFormRef: null,
      customCheck: false,
      counter: 60,
      sendBtnShow: true,
      confirmAge: true,
      ageTips: false,
      showPassword: false,
      showPassword2: false,
    });
    const validateRePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        if (value !== state.rForm.password) {
          callback(new Error("两次密码输入不一致！"));
        }
        callback();
      }
    };
    const rRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      sms: [{ validator: validateNull, trigger: "blur" }],
      password: [{ validator: validatePassword, trigger: "blur" }],
      rePassword: [{ validator: validateRePassword, trigger: "blur" }],
    };
    const register = () => {
      state.rFormRef.validate((valide) => {
        if (valide) {
          if (state.customCheck) {
            toRegister({
              password: proxy.$encryption(state.rForm.password),
              mobile: state.rForm.tel,
              code: state.rForm.sms,
            }).then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: "注册成功，即将跳转登录页......",
                  type: "success",
                });
                setTimeout(() => router.push("/login"), 3000);
              } else {
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            });
          } else {
            ElMessage({
              message: "请阅读并选中协议～",
              type: "warning",
            });
          }
        }
      });
    };
    const setTime = () => {
      var timer;
      if (state.sendBtnShow) {
        state.sendBtnShow = false;
        timer = setInterval(() => {
          if (state.counter > 0 && state.counter <= 60) {
            state.counter--;
          } else {
            state.sendBtnShow = true;
            state.counter = 60;
            clearInterval(timer);
          }
        }, 1000);
      }
    };
    //发送验证码
    const sendVerify = (tel, type, userId, password) => {
      if (/^1[0-9]{10}$/.test(tel)) {
        //校验手机号是否正确
        toSendVerify({
          mobile: tel,
          type: type,
          userId: userId,
          password: password,
        })
          .then((response) => {
            if (response["status"] == 0) {
              ElMessage({
                message: response["msg"],
                type: "success",
              });
            } else {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
              state.sendBtnShow = true;
              state.counter = 60;
            }
          })
          .catch(() => {
            state.sendBtnShow = true;
            state.counter = 60;
          });
      } else {
        ElMessage({
          message: "请输入正确的手机号！",
          type: "warning",
        });
        state.counter = 0;
      }
    };
    return {
      ...toRefs(state),
      register,
      sendVerify,
      verifyCode,
      rRules,
      setTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.tipText {
  line-height: 2rem;
  text-indent: 2rem;
  font-size: 1rem;
}
.confirmTipText {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tips {
  width: 50%;
  margin: 30px auto;
  height: 40px;
  margin-bottom: -50px;
  border: 1px solid #e6a23c;
}
</style>
